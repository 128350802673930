<template>
  <div>
    <v-col align="center">
        <h2>:: Catalog ::</h2>
      <v-card class="pt-10 mt-10" height="200" width="300" elevation="8">
        <v-col align="center">
          <v-row justify="center"
            ><v-btn
              class="button"
              @click="$router.push('/category/family_ring')"
              color="pink darken-4"
            >
              <h4 style="color:#cdb96e;">แหวนนามสกุล</h4>
            </v-btn></v-row
          >
          <v-row justify="center" class="pt-5"
            ><v-btn
              class="button"
              @click="$router.push('/category/neck')"
              color="pink darken-4"
            >
              <h4 style="color:#cdb96e;">สร้อยคอ</h4>
            </v-btn></v-row
          >
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Category",
  data() {
    return {};
  },
};
</script>

<style scoped>
.button {
  width: 100px;
  height: 50px;
  font-weight: bold;
}
</style>
