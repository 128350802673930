import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Order from '../views/Order.vue'
import History from '../views/History.vue'
import Profile from '../views/Profile.vue'
import Category from '../views/Category.vue'
import FamilyRing from '../views/Family_ring.vue'
import Neck from '../views/Neck.vue'
import OrderFamilyRing from '../views/Order_family_ring.vue'
import OrderNeck from '../views/Order_neck.vue'
import OrderPendant from '../views/Order_pendant.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Category',
    component: Category
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/profile',
    name: 'Proflie',
    component: Profile
  },
  {
    path: '/category',
    name: 'Category',
    component: Category
  },
  {
    path: '/category/family_ring',
    name: 'FamilyRing',
    component: FamilyRing
  },
  {
    path: '/category/neck',
    name: 'Neck',
    component: Neck
  },
  {
    path: '/order/family_ring',
    name: 'OrderFamilyRing',
    component: OrderFamilyRing
  },
  {
    path: '/order/neck',
    name: 'OrderNeck',
    component: OrderNeck
  },
  {
    path: '/order/pendant',
    name: 'OrderPendant',
    component: OrderPendant
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
