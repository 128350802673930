<template>
  <div>
    <v-col align="center">
      <v-card>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-container class="py-3">
                <v-text-field
                  v-model="surname"
                  class="textF"
                  label="ข้อความ"
                  :rules="[rules.required]"
                  @change="checkChar($event)"
                  outlined
                ></v-text-field>
              </v-container>
              <v-container class="py-3">
                <v-sheet height="50" width="300">
                  <v-select
                    :items="fonts"
                    label="รูปแบบตัวอักษร"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    @change="eventFont($event)"
                    v-model="font"
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      {{ getText(item) }}
                      <h3 :style="{ fontFamily: item.font }">{{ surname }}</h3>
                    </template>
                  </v-select>
                </v-sheet>
              </v-container>
              <v-container>
                <v-sheet width="300" height="50" elevation="8" :color="colorBG">
                  <!-- <div v-if="this.font == 'T02'">
                    <v-img
                      width="250"
                      height="45"
                      :src="require('../assets/TS-Phikul.png')"
                      class="pt-2"
                    />
                  </div> -->
                  <!-- <div v-else-if="this.font == 'T08'">
                    <v-img
                      width="250"
                      height="45"
                      :src="require('../assets/pierre.png')"
                      class="pt-2"
                    />
                  </div> -->
                  <!-- <div v-else>
                    <h2
                      class="pt-2"
                      :style="{ fontFamily: changeFont(), color: colorFT }"
                    >
                      {{ surname == "" ? "รูปแบบข้อความ" : surname }}
                    </h2>
                  </div> -->
                  <div>
                    <h2
                      class="pt-2"
                      :style="{ fontFamily: changeFont(), color: colorFT }"
                    >
                      {{ surname == "" ? "รูปแบบข้อความ" : surname }}
                    </h2>
                  </div>
                </v-sheet>
              </v-container>
              <h3 class="text-center">
                น้ำหนักทอง
              </h3>
            </v-col>
            <v-btn-toggle v-model="toggle_exclusive">
              <v-btn
                @click="showPosition((value = '2s'))"
                class="button"
                :disabled="this.surname.length > 10"
              >
                2-3 สลึง
              </v-btn>
              <v-btn
                @click="showPosition((value = '3s'))"
                class="button"
                :disabled="this.surname.length > 15"
              >
                3 สลึง - 1 บาท
              </v-btn>
              <v-btn
                @click="showPosition((value = '1b'))"
                class="button"
                :disabled="this.surname.length > 20"
              >
                1 บาท ขึ้นไป
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-text>
        <v-sheet elevation="8" height="200" width="300">
          <v-card width="300" height="200">
            <div v-if="show1">
              <v-carousel
                hide-delimiters
                @change="showDetailPendant($event), reset()"
                height="200"
              >
                <v-carousel-item
                  v-for="(item, i) in this.pendant"
                  :key="i"
                  :value="item"
                  :src="`data:image/jpg; base64,` + img"
                  max-width="200"
                  max-height="200"
                  contain
                ></v-carousel-item>
              </v-carousel>
            </div>
            <div v-if="show2">
              <v-col>
                <h3>กรุณาเลือกน้ำหนักทอง</h3>
              </v-col>
            </div>
          </v-card>
        </v-sheet>
        <v-sheet height="50" width="300" class="mt-3">
          <v-select
            @change="selectByCode(), reset()"
            :items="this.codePendant"
            item-text="name"
            :item-value="items"
            label="รหัสจี้"
            outlined
            :rules="[rules.required]"
            return-object
            v-model="codeP"
          ></v-select>
        </v-sheet>
        <h3 class="pt-5">ตำแหน่งห่วง</h3>
        <v-sheet elevation="8" height="100" width="300" class="mt-3">
          <v-card width="300" height="100">
            <div v-if="show1">
              <v-carousel
                hide-delimiters
                @change="showDetailPosition($event), reset()"
                height="100"
              >
                <v-carousel-item
                  v-for="(item, i) in this.position[0]"
                  :key="i"
                  :value="item"
                  :src="`data:image/jpg; base64,` + imgP"
                  max-width="200"
                  max-height="100"
                ></v-carousel-item>
              </v-carousel>
            </div>
            <div v-if="show2">
              <v-col>
                <h3>ตำแหน่งห่วง</h3>
              </v-col>
            </div>
          </v-card>
        </v-sheet>
        <v-container class="py-3">
          <v-text-field
            class="textF"
            v-model="note"
            label="หมายเหตุ"
            outlined
          ></v-text-field>
        </v-container>
        <v-row class="pt-10 pb-10" align="center" justify="space-around">
          <v-btn class="button" @click="clear" color="pink darken-4"
            ><h4 style="color:#cdb96e;">CLEAR</h4></v-btn
          >
          <v-btn class="button" @click="saveOrder" color="pink darken-4"
            ><h4 style="color:#cdb96e;">SAVE</h4></v-btn
          >
        </v-row>
      </v-card>
      <Dialog />
      <DialogWarning />
    </v-col>
  </div>
</template>

<script>
import Dialog from "../components/dialog.vue";
import DialogWarning from "../components/dialogWarning.vue";
import liff from "@line/liff";
// import { mapState } from "vuex";
export default {
  name: "",
  components: { Dialog, DialogWarning },
  data: () => ({
    position: [],
    weight: "",
    colorFT: "#b08f26",
    colorBG: "#cdb96e",
    bg_id: "10",
    ft_id: "11",
    surname: "",
    note: "",
    img: "",
    name: "",
    variant: "",
    font: "",
    pendants: [],
    pendant: [],
    codePendant: [],
    codeP: "",
    product_id: "",
    imgP: "",
    nameP: "",
    show1: false,
    show2: true,
    fonts: [
      {
        name: "[T01] TS-kaewphet Extra NP",
        id: "T01",
        font: "ts-kaewpet_extra-npitalic",
      },
      { name: "[T03] Kt-smarn", id: "T03", font: "kt_smarnregular" },
      { name: "[T04] DSN MontaNa", id: "T04", font: "js_wansikaitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      { name: "[T07] DSN WatTana", id: "T07", font: "js_amphanitalic" },
      {
        name: "[T09] TS-som Tum-A-NP",
        id: "T09",
        font: "ts-som_tum_-a-npregular",
      },
      { name: "[T10] DSN Cologne", id: "T10", font: "dsn_cologneregular" },
      {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      { name: "[E02] Ginchiest", id: "E02", font: "ginchiestregular" },
      {
        name: "[E03] Champion Shipmate",
        id: "E03",
        font: "champion_shipmateitalic",
      },
      {
        name: "[E04] Brush Script Std",
        id: "E04",
        font: "Brush Script Std",
      },
      { name: "[E05] Chopinscript", id: "E05", font: "chopinscriptmedium" },
      {
        name: "[E06] Motion Picture Personal Use",
        id: "E06",
        font: "motion_picture_personal_useRg",
      },
      {
        name: "[E07/T05] Book_Akhanake",
        id: "E07/T05",
        font: "book_akhanakeregular",
      },
    ],
    rules: {
      required: (value) => !!value,
    },
    rule23s: [
      (v) => !!v,
      (v) => v.length <= 4 || "Name must be less than 4 characters",
    ],
    rule3s1b: [
      (v) => !!v,
      (v) => v.length <= 6 || "Name must be less than 5-6 characters",
    ],
    rule1b: [
      (v) => !!v,
      (v) => v.length <= 12 || "Name must be less than 6-12 characters",
    ],
    fontThai: [
      { name: "[T01] Kt-smarn", id: "T01", font: "kt_smarnregular" },
      { name: "[T02] DSN MontaNa", id: "T02", font: "js_wansikaitalic" },
      {
        name: "[T03] Book_Akhanake",
        id: "T03",
        font: "book_akhanakeregular",
      },
      { name: "[T04] DSN FreeJack", id: "T04", font: "js_jetarinitalic" },
      { name: "[T05] DSN WatTana", id: "T05", font: "js_amphanitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      {
        name: "[T07] TS-som Tum-A-NP",
        id: "T07",
        font: "ts-som_tum_-a-npregular",
      },
    ],
    fontsAll: [
      { name: "[T01] Kt-smarn", id: "T01", font: "kt_smarnregular" },
      { name: "[T02] DSN MontaNa", id: "T02", font: "js_wansikaitalic" },
      {
        name: "[T03] Book_Akhanake",
        id: "T03",
        font: "book_akhanakeregular",
      },
      { name: "[T04] DSN FreeJack", id: "T04", font: "js_jetarinitalic" },
      { name: "[T05] DSN WatTana", id: "T05", font: "js_amphanitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      {
        name: "[T07] TS-som Tum-A-NP",
        id: "T07",
        font: "ts-som_tum_-a-npregular",
      },
      {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      {
        name: "[E02] Branndoll Ny Personal Use Only",
        id: "E02",
        font: "brannboll_ny_personal_use_oRg",
      },
      { name: "[E03] Ginchiest", id: "E03", font: "ginchiestregular" },
      {
        name: "[E04] Champion Shipmate",
        id: "E04",
        font: "champion_shipmateitalic",
      },
      {
        name: "[E05] Motion Picture Personal Use",
        id: "E05",
        font: "motion_picture_personal_useRg",
      },
      { name: "[E06] JS Pranee", id: "E06", font: "js_praneeitalic" },
      {
        name: "[E07] Book_Akhanake",
        id: "E07",
        font: "book_akhanakeregular",
      },
    ],
    fonteng: [
    {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      {
        name: "[E02] Branndoll Ny Personal Use Only",
        id: "E02",
        font: "brannboll_ny_personal_use_oRg",
      },
      { name: "[E03] Ginchiest", id: "E03", font: "ginchiestregular" },
      {
        name: "[E04] Champion Shipmate",
        id: "E04",
        font: "champion_shipmateitalic",
      },
      {
        name: "[E05] Motion Picture Personal Use",
        id: "E05",
        font: "motion_picture_personal_useRg",
      },
      { name: "[E06] JS Pranee", id: "E06", font: "js_praneeitalic" },
      {
        name: "[E07] Book_Akhanake",
        id: "E07",
        font: "book_akhanakeregular",
      },
    ],
  }),
  // computed: {
  //   ...mapState({
  //     userProfile: (state) => state.sgjorder.userProfile,
  //   }),
  // },
  async mounted() {
    this.pendants = await this.$store.dispatch("getProduct", "pendant");
    await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF });
    liff.ready.then(() => {
      if (!liff.isLoggedIn()) {
        liff.login();
      }
      this.getUserProfile();
    });
    // if (this.userProfile.state != "done") {
    //   this.$router.push("/order");
    // }
  },
  methods: {
    showPosition(value) {
      let i, j;
      this.position = [];
      this.pendant = [];
      this.weight = value;
      this.show2 = false;
      this.show1 = true;
      this.position.push(this.pendants[1]["variant_position_pendant"]);
      for (i = 0; i < this.pendants.length; i++) {
        for (j = 0; j < this.pendants[i]["variant_goldweight"].length; j++) {
          if (this.pendants[i]["variant_goldweight"][j]["value"] == value) {
            this.pendant.push(this.pendants[i]);
            this.weight = this.pendants[i]["variant_goldweight"][j]["id"];
            this.codePendant.push(this.pendants[i]);
          }
        }
      }
    },
    showDetailPosition(event) {
      this.imgP = event.variant_image;
      this.nameP = event.name;
      this.variant = event.id;
    },
    showDetailPendant(event) {
      this.code = event.name;
      this.codeP = event.name;
      this.name = event.name;
      this.img = event.cover_image;
      this.product_id = event.id;
    },
    selectByCode() {
      this.code = this.codeP.name;
      this.name = this.codeP.name;
      this.img = this.codeP.cover_image;
      this.product_id = this.codeP.id;
    },
    eventFont(event) {
      this.font = event;
    },
    saveOrder() {
      if (this.surname != "" && this.font != "" && this.code != "") {
        this.variant_all = [this.weight, this.variant, this.bg_id, this.ft_id];
        this.$store.commit("SET_DETAIL", {
          product_id: this.product_id,
          product_type: "pendant",
          code: this.code,
          name: this.name,
          text: this.surname,
          note: this.note,
          img: this.img,
          variants: this.variant_all,
          variant_weight: this.weight,
          variant_background: this.bg_id,
          variant_position_pendant: this.variant,
          variant_font: this.ft_id,
          font: this.font,
        });
        this.$store.commit("SET_DIALOG", true);
      } else {
        this.$store.commit("SET_DIALOGW", true);
      }
    },
    reset() {
      this.colorFT = "#b08f26";
      this.colorBG = "#cdb96e";
      this.bg_id = "10";
      this.ft_id = "11";
    },
    getText(item) {
      return `${item.id} - `;
    },
    clear() {
      this.surname = "";
      this.note = "";
      this.font = "";
      this.colorFT = "#b08f26";
      this.colorBG = "#cdb96e";
    },
    checkChar(event) {
      let max = 0;
      for (let i = 0; i < event.length; i++) {
        if (event.charCodeAt(i) > max) {
          max = event.charCodeAt(i);
          console.log(max);
        }
      }
      if (max <= 122) {
        this.fonts = this.fonteng;
      } else if (max <= 3660) {
        this.fonts = this.fontThai;
      } else {
        this.fonts = this.fontsAll;
      }
    },
    changeFont() {
      if (this.font == "E01") {
        return "kunstler_scriptregular";
      } else if (this.font == "E02") {
        return "brannboll_ny_personal_use_oRg";
      } else if (this.font == "E03") {
        return "ginchiestregular";
      } else if (this.font == "E04") {
        return "champion_shipmateitalic";
      } else if (this.font == "E05") {
        return "motion_picture_personal_useRg";
      } else if (this.font == "E06") {
        return "js_praneeitalic";
      } else if (this.font == "E07") {
        return "book_akhanakeregular";
      }  else if (this.font == "T01") {
        return "kt_smarnregular";
      } else if (this.font == "T02") {
        return "js_wansikaitalic";
      } else if (this.font == "T03") {
        return "book_akhanakeregular";
      } else if (this.font == "T04") {
        return "js_jetarinitalic";
      } else if (this.font == "T05") {
        return "js_amphanitalic";
      } else if (this.font == "T06") {
        return "waspierreregular";
      }  else if (this.font == "T07") {
        return "ts-som_tum_-a-npregular";
      } else {
        return "Kanit", "sans-serif";
      }
    },
    async getUserProfile() {
      const profile = await liff.getProfile();
      const user = {
        line_uuid: profile.userId,
        display_name: profile.displayName,
        picture_url: profile.pictureUrl,
      };
      this.$store.commit("SET_USER", user);
      this.$store.commit("SET_SHOWBTN", true);
      this.$store.dispatch("createLineUser", user);
      let userState = await this.$store.dispatch("getUser", user.line_uuid);
      console.log('stateUser',userState)
      if (userState.state != "done") {
      this.$router.push("/order");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap");
* {
  font-family: "Kanit", sans-serif;
  letter-spacing: 1px;
}
@font-face {
  font-family: "ginchiestregular";
  src: url("../assets/fontsss/ginchiest-webfont.woff2") format("woff2"),
    url("../assets/fontsss/ginchiest-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brush Script Std";
  src: url("../assets/fontsss/kunstler-webfont.woff2") format("woff2"),
    url("../assets/fontsss/kunstler-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ts-phikun_2.2-npbold_italic";
  src: url("../assets/fontsss/ts-phikun_2.2a_-np-webfont.woff2") format("woff2"),
    url("../assets/fontsss/ts-phikun_2.2a_-np-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "waspierreregular";
  src: url("../assets/fontsss/waspierre-bold-webfont.woff2") format("woff2"),
    url("../assets/fontsss/waspierre-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kunstler_scriptregular";
  src: url("../assets/fonts/BrushScriptStd.woff2") format("woff2"),
    url("../assets/fonts/BrushScriptStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kt_smarnregular";
  src: url("../assets/fonts/ktsmarn.woff2") format("woff2"),
    url("../assets/fonts/ktsmarn.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "motion_picture_personal_useRg";
  src: url("../assets/fontsss/motionpicture_personaluseonly-webfont.woff2")
      format("woff2"),
    url("../assets/fontsss/motionpicture_personaluseonly-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "book_akhanakeregular";
  src: url("../assets/fonts/BookAkhanake.woff2") format("woff2"),
    url("../assets/fonts/BookAkhanake.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_praneeitalic";
  src: url("../assets/fontsss/jspranee-italic-webfont.woff2") format("woff2"),
    url("../assets/fontsss/jspranee-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "chopinscriptmedium";
  src: url("../assets/fontsss/chopinscript-webfont.woff2") format("woff2"),
    url("../assets/fontsss/chopinscript-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "brannboll_ny_personal_use_oRg";
  src: url("../assets/fontsss/brannbollnypersonaluseonly-webfont.woff2")
      format("woff2"),
    url("../assets/fontsss/brannbollnypersonaluseonly-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "champion_shipmateitalic";
  src: url("../assets/fontsss/championshipmate-italic-webfont.woff2")
      format("woff2"),
    url("../assets/fontsss/championshipmate-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ts-kaewpet_extra-npitalic";
  src: url("../assets/fonts/TS-kaewPetExtra-NP-Italic.woff2") format("woff2"),
    url("../assets/fonts/TS-kaewPetExtra-NP-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ts-som_tum_-a-npregular";
  src: url("../assets/fonts/TS-Somtum-A-NP.woff2") format("woff2"),
    url("../assets/fonts/TS-Somtum-A-NP.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "vivaldiregular";
  src: url("../assets/fontsss/vivaldi-webfont.woff2") format("woff2"),
    url("../assets/fontsss/vivaldi-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "wastawarawadeetestregular";
  src: url("../assets/fontsss/wastawarawadeetest-webfont.woff2") format("woff2"),
    url("../assets/fontsss/wastawarawadeetest-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dsn_cologneregular";
  src: url("../assets/fonts/DSNCologne.woff2") format("woff2"),
    url("../assets/fonts/DSNCologne.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_wansikaitalic";
  src: url("../assets/fonts/JSWansika-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSWansika-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "js_amphanitalic";
  src: url("../assets/fonts/JSAmphan-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSAmphan-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_jetarinitalic";
  src: url("../assets/fonts/JSJetarin-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSJetarin-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.button {
  width: 100px;
  height: 50px;
  font-weight: bold;
}

.textF {
  width: 300px;
  height: 50px;
}

.box {
  width: 200px;
  height: 200px;
}

.size {
  width: 300px;
  height: 50px;
}
</style>
