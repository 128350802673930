<template>
  <div>
    <v-col>
      <v-card
        class="mx-auto"
        height="200"
        outlined
        color="pink darken-4"
        elevation="7"
      >
        <v-list-item three-line>
          <v-list-item-avatar tile size="100">
            <img :src="pic" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-overline mb-1">
              <h4 style="color:#cdb96e;">
                ชื่อร้านค้า :
                {{
                  userProfile.name == false ? "รอการอนุมัติ" : userProfile.name
                }}
              </h4>
            </v-list-item-title>
            <v-list-item-title class="text-overline mb-1">
              <h4 style="color:#cdb96e;">รหัสสมาชิก : {{ userProfile.id }}</h4>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions class="pt-5"
          ><v-list-item-subtitle class="text-overline mb-1"
            ><h4 style="color:#cdb96e;">
              สถานะยืนยันตัวตน :
              {{ check_approve() }}
            </h4></v-list-item-subtitle
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <DialogCheckName />
  </div>
</template>

<script>
import { mapState } from "vuex";
import liff from "@line/liff";
import DialogCheckName from "../components/dialogCheckName.vue";
export default {
  name: "Profile",
  components: { DialogCheckName },
  data: () => ({
    loading: false,
    selection: 1,
    level: "",
    pic: "",
  }),

  methods: {
    check_level() {
      if (this.userProfile.level == "premium_economy") {
        return "Premium Economy";
      } else if (this.userProfile.level == "business") {
        return "Business Class";
      } else if (this.userProfile.level == "first") {
        return "First Class";
      }
    },
    check_approve() {
      if (this.userProfile.state == "new") {
        return "รอการยืนยัน";
      } else if (this.userProfile.state == "done") {
        return "ยืนยันตัวตนแล้ว";
      } else if (this.userProfile.state == "cancel") {
        return "ข้อมูลไม่ถูกต้อง (กรูณาติดต่อแอดมิน)";
      }
    },
    async getUserProfile() {
      const profile = await liff.getProfile();
      const user = {
        line_uuid: profile.userId,
        display_name: profile.displayName,
        picture_url: profile.pictureUrl,
      };

      this.$store.commit("SET_USER", user);
      this.$store.commit("SET_SHOWBTN", true);
      this.$store.dispatch("createLineUser", user);
      await this.$store.dispatch("getUser", user.line_uuid);
      this.pic = profile.pictureUrl;
      let data = {
        id: this.userProfile.id,
        picture_url: this.pic,
        line_uuid: this.userProfile.line_uuid,
      };
      await this.$store.dispatch("addPic", data);
    },
    async checkName() {
      if (this.userProfile.name == false) {
        this.$store.commit("SET_DIALOGW", true);
      } else {
        console.log(true);
      }
    },
    // async addPic() {
    //   let data = {
    //     id: this.userProfile.id,
    //     picture_url: this.pic,
    //     line_uuid: this.userProfile.line_uuid,
    //   };

    // },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  async mounted() {
    await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF  });
    liff.ready.then(() => {
      if (!liff.isLoggedIn()) {
        liff.login();
      }
      this.getUserProfile();
    });
    this.checkName();
    // this.addPic();
  },
};
</script>
