<template>
  <v-app>
    <v-app-bar app color="pink darken-4" dense dark>
      <img :src="image1" class="image" @click="$router.push('/home')" />
      <v-spacer></v-spacer>
      <v-btn
        v-show="showbtn"
        icon
        @click="$router.push('/order')"
        color="#cdb96e"
      >
        <v-icon>mdi-cart-plus</v-icon>
      </v-btn>
      <v-btn
        v-show="showbtn"
        icon
        @click="$router.push('/history')"
        color="#cdb96e"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn icon @click="$router.push('/category')" color="#cdb96e">
        <v-icon>mdi-book-open-blank-variant</v-icon>
      </v-btn>
      <v-btn icon @click="$router.push('/profile')" color="#cdb96e">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import 'dotenv/config'
import liff from "@line/liff";
export default {
  name: "App",

  data() {
    return {
      image1: require("./assets/sh.png"),
    };
  },
  computed: {
    ...mapState({
      showbtn: (state) => state.sgjorder.showbtn,
      userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  async mounted() {
    await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF });
    liff.ready.then(() => {
      if (liff.isLoggedIn()) {
        this.getUserProfile();
      }
    });
  },
  methods: {
    async getUserProfile() {
      const profile = await liff.getProfile();
      const user = {
        line_uuid: profile.userId,
        display_name: profile.displayName,
        picture_url: profile.pictureUrl,
      };
      this.$store.commit("SET_USER", user);
      this.$store.commit("SET_SHOWBTN", true);
      this.$store.dispatch("createLineUser", user);
      await this.$store.dispatch("getUser", user.line_uuid);
      console.log(user);
      let data = {
        id: this.userProfile.id,
        picture_url: this.userProfile.picture_url,
        line_uuid: this.userProfile.line_uuid,
      };
      await this.$store.dispatch("addPic", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap");
* {
  font-family: "Kanit", sans-serif;
  letter-spacing: 1px;
}

.text {
  color: #cdb96e;
  font-weight: bold;
}
.image {
  width: 180px;
  margin-top: 5px;
}
</style>
