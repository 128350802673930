<template>
  <div>
    <v-col align="center">
      <h2>รายการสินค้า</h2>
      <v-card class="pt-10 mt-10" height="200" width="300" elevation="8">
        <v-col align="center">
          <v-row justify="center">
            <v-btn
              class="button"
              @click="$router.push('/order/family_ring')"
              color="pink darken-4"
            >
              <h4 style="color:#cdb96e;">แหวนนามสกุล</h4>
            </v-btn>
          </v-row>
          <v-row justify="center" class="pt-5">
            <v-btn class="button" @click="neck()" color="pink darken-4">
              <h4 style="color:#cdb96e;">สร้อยคอ</h4></v-btn
            >
          </v-row>
          <v-row justify="center" class="pt-5">
            <v-btn class="button" @click="pendant()" color="pink darken-4">
              <h4 style="color:#cdb96e;">จี้</h4></v-btn
            >
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import liff from "@line/liff";
import { mapState } from "vuex";
export default {
  name: "Order",
  data() {
    return {};
  },
  methods: {
    async getUserProfile() {
      const profile = await liff.getProfile();
      const user = {
        line_uuid: profile.userId,
        display_name: profile.displayName,
        picture_url: profile.pictureUrl,
      };
      this.$store.commit("SET_USER", user);
      this.$store.commit("SET_SHOWBTN", true);
      this.$store.dispatch("createLineUser", user);
      await this.$store.dispatch("getUser", user.line_uuid);
    },
    check_approved() {
      if (this.userProfile.state != "done") {
        this.$router.push("/profile");
      }
    },
    neck() {
      this.$router.push("/order/neck");
    },
    pendant(){
        this.$router.push("/order/pendant");
    }
  },
  async mounted() {
    await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF });
    liff.ready.then(() => {
      if (!liff.isLoggedIn()) {
        liff.login();
      }
      this.getUserProfile();
    });
    this.check_approved();
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.sgjorder.userProfile,
      user: (state) => state.sgjorder.user,
    }),
  },
};
</script>

<style scoped>
.button {
  width: 100px;
  height: 50px;
  font-weight: bold;
}
</style>
