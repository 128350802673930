<template>
  <div>
    <v-card flat class="py-12">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-btn-toggle v-model="toggle_exclusive">
            <v-btn @click="showOrder((value = 'open'))">
              ยืนยัน
            </v-btn>
            <v-btn @click="showOrder((value = 'done'))">
              เสร็จสิ้น
            </v-btn>
            <v-btn @click="showOrder((value = 'done'))">
              รับสินค้า
            </v-btn>
            <v-btn @click="showOrder((value = 'draft'))">
              รอยืนยัน
            </v-btn>
            <v-btn @click="showOrder((value = ''))">
              ทั้งหมด
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="product"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "History",

  data() {
    return {
      products: [],
      product: [],
      isShow: false,
      toggle_exclusive: false,
      headers: [
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Date", value: "date_order" },
        { text: "Product", value: "product_name" },
        { text: "Variants", value: "product_variants" },
        { text: "Size", value: "size" },
        { text: "Text", value: "text" },
        { text: "Status", value: "state" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.sgjorder.user,
      order: (state) => state.sgjorder.order,
    }),
  },
  methods: {
    showOrder(value) {
      let i;
      this.product = [];

      if (value == "") {
        for (i = 0; i < this.products.length; i++) {
          this.product.push(this.products[i]);
        }
      } else {
        for (i = 0; i < this.products.length; i++) {
          if (this.products[i]["state"] == value) {
            this.product.push(this.products[i]);
          }
        }
      }
      console.log(this.product);
    },
  },
  async mounted() {
    this.products = await this.$store.dispatch("getOrder", this.user.line_uuid);
    this.product = this.products;
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap');
* {
  font-family: 'Kanit', sans-serif;
  letter-spacing: 1px;
}
.box {
  width: 150px;
  height: 60px;
}
</style>
